


export const Footer = () => {

    return (
        <div className="border border-red-300">
            Footer
        </div>
    )
}